import { Box, Dialog, IconButton } from "@mui/material"
import { styled } from '@mui/system';

import verticalRope from "../../assets/verticalRope.svg"
import CloseIcon from '@mui/icons-material/Close';


const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiBackdrop-root ": {
        backgroundColor: "#e1e3e069"
    },
    "& .MuiPaper-root ": {
        borderRadius: "30px", maxWidth: "40rem", backgroundColor: "#f0f3f0"
    }

}))
const StyledBox = styled(Box)(({ theme, ...props }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
    padding: "1rem",
    width: props?.width ? props.width : '40rem',
    marginBottom: "1rem",
    height: props.height,
    maxWidth: "100%"
}))
const StyledIcon = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "7rem",
    width: "23px",
    height: "142px"
}))
const GenericDialog = ({ open, setOpen, children, iconSVG, showRopes, height, showExit = true, width = false }) => {

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setOpen(false)
    };
    return (
        <StyledDialog
            open={open}
            onClose={handleClose}
        >
            <StyledBox height={height} width={width}>
                {showRopes && <>
                    {/* rope left */}
                    < StyledIcon component="img"
                        sx={{ display: { xs: "none", sm: "block" } }}
                        left="1rem"
                        alt={"..."}
                        src={verticalRope}
                    />
                    {/* rope right */}
                    <StyledIcon component="img"
                        sx={{ display: { xs: "none", sm: "block" } }}
                        right="1rem"
                        alt={"..."}
                        src={verticalRope}
                    /></>}
                {/* exit btn */}
                {showExit && <IconButton sx={{ position: "absolute", right: ".5rem", top: ".5rem" }} onClick={() => setOpen(false)} >
                    <CloseIcon sx={{ color: "#3a7735" }} />
                </IconButton>}
                {!!iconSVG &&
                    <Box component="img" mt="1rem" mb="1.5rem"
                        src={iconSVG}
                        width="110px"
                        height="100px"
                    />}
                {children}
            </StyledBox>
        </StyledDialog >

    )
}
export default GenericDialog;
