import { useState } from 'react';
import { useNavigate } from 'raviger'
import { useSnackbar } from 'notistack';
import useGlobal from "../../store/store";
//libs
import { format } from 'date-fns'
import Switch from "react-switch";
//mui
import { makeStyles } from '@mui/styles';
import { Link, Box, Card, Chip, CardHeader, CardContent, CardActions, Collapse, Typography, IconButton, Tooltip } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkIcon from '@mui/icons-material/Link';
import SubtitlesOffOutlinedIcon from '@mui/icons-material/SubtitlesOffOutlined';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
//api
import { addRegAPI } from 'api/api';
//components
import { ConditionalWrapper } from 'Components/Common/ConditionalWrapper';
import { useTranslation } from 'react-i18next';
import { permanentInfo } from 'data/permanentInfo';
import { CustomBusy } from 'Components/Common/CustomBusy';


export const activityUseStyles = makeStyles((theme) => ({
    activityCard: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: `#ffffff !important`,
        borderRadius: '20px !important',
        border: '1px solid #0000004d',
        paddingTop: "0.5rem",
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.04) !important',
        '& .MuiCardHeader-title': {
            lineHeight: '15px',
            fontSize: '1rem',
            fontWeight: '700',
            color: '#333333',
            textAlign: 'initial'
        }
    },
    cardHeader: {
        padding: " 0.25rem 0.25rem !important",
        '& .MuiCardHeader-title': {
            lineHeight: '15px',
            fontSize: '1rem',
            fontWeight: '700',
            color: '#333333',
            textAlign: 'initial'
        }
    },
    activityCardHeight: {
        height: '270px'
    },
    greenInfoLabel: {
        borderRadius: '2rem',
        fontWeight: '600',
        padding: '0rem .5rem !important',
        margin: "0.2rem",
        backgroundColor: '#e3f7e1  !important'
    },
    grayInfoLabel: {
        fontWeight: '600',
        borderRadius: '2rem',
        padding: '0rem .5rem !important',
        margin: "0.2rem",
        backgroundColor: '#dbdbdb  !important'
    },
    ActivChip: {
        "& .MuiChip-label": {
            display: 'flex',
            justifyContent: 'center',
        },
        marginLeft: '0.2rem !important',
        height: '1rem !important',
        color: `${theme.palette.green.darker} !important`,
        backgroundColor: 'transparent !important',
        border: `1px solid ${theme.palette.green.darker} !important`
    },
    disabledItem: {
        border: '1px solid #0000004d',
        zIndex: '999',
        cursor: 'default',
        pointerEvents: 'none',
        boxShadow: 'none !important;',
        '& .MuiTypography-root:not(.MuiTypography-activityTxtBold)': {
            color: '#dbdbdb !important;'
        },
        '& .MuiPaper-root': {
            boxShadow: 'none !important'
        },
        '& .MuiSvgIcon-root': {
            color: '#dbdbdb'
        },
        '& .MuiChip-root': {
            backgroundColor: '#dbdbdb !important'
        }
    },
    itemSelected: {
        border: '2px solid #007f00 !important;',
    },
    ribbonStyle: {
        lineHeight: '12px !important;'
    },
    iconStyle: {
        marginLeft: '0.2rem',
        width: "1rem"
    },
    switch: {
        alignSelf: "flex-end !important",
        '& .react-switch-bg': {
            boxShadow: "6px 6px 10px rgba(0,0,0,0.16)",
            '& div': {
                width: '81px !important',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }
        },
        '& .react-switch-handle': {
            width: "32px !important",
            height: "30px !important"
        }
    },
    selectBorder: {
        '& .react-switch-handle': {
            border: "2px solid #ffbb41 !important",
        }
    },
    unSelectBorder: {
        '& .react-switch-handle': {
            border: "2px solid #3a7735 !important",
        }
    },
    noBorder: {
        '& .react-switch-handle': {
            border: "none !important",
            background: "none !important",
            '& div': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }
        }
    },
    grayBG: {
        '& .react-switch-bg': {
            background: "#00000029 !important",
            boxShadow: "none !important",
        },
        '& .MuiTypography-root:not(.MuiTypography-activityTxtBold)': {
            color: '#a2a2a2 !important'
        },
        '& .MuiSvgIcon-root': {
            color: '#a2a2a2 !important'
        },
    }
}));

export default function ActivityItem({ props, isParent, setKibbutzUser, addProduct, household, setOpenSummerNote }) {
    const classes = activityUseStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);
    const [busy, setBusy] = useState(false);


    const [cartState, setCartState] = useGlobal((state) => state.cartState, (actions) => actions.setCartState);
    const [householdSelections, [removeHouseholdSelection, addHouseholdSelection]] = useGlobal((state) => state.householdSelections, (actions) => ([actions.removeHouseholdSelection, actions.addHouseholdSelection]));
    const [activCode, setActivCode] = useGlobal((state) => state.activCode, (actions) => actions.setActivCode);
    const [unlockActivityDrawer, setUnlockActivityDrawer] = useGlobal(state => (state).unlockActivityDrawer, (actions) => actions.setUnlockActivityDrawer);
    const [activityToUnlock, setActivityToUnlock] = useGlobal((state) => state.activityToUnlock, (actions) => actions.setActivityToUnlock);


    const handleCart = async (e) => {
        setBusy(true);
        if (e) { //activity selected
            try {
                const regReq = await addRegistrationRequest();
                addHouseholdSelection(regReq);
                addProduct(regReq);
                //add registration requests to db prior showing cart
                const isKibbutz = props.Type === "02";
                setKibbutzUser(isKibbutz);
                if (props.isSummerAct) {
                    setOpenSummerNote(true);
                }
                // setCartState(true);
                const key = enqueueSnackbar(t('itemAddedSuccessfuly'), { variant: "success" });
                setTimeout(() => { closeSnackbar(key) }, 1000)
            } catch (error) {
                enqueueSnackbar(error?.message || t("Error"), { variant: "error" });
            } finally {
                setBusy(false);
            }
        }
        else { //activity unselected
            try {
                removeHouseholdSelection(props); //remove from household selections
            } catch (error) {
                console.log(error);
            } finally {
                setBusy(false);
            }
        }
    };
    const addRegistrationRequest = async () => {
        const activity = props;
        const child = household.ChildrenList.results.find(c => { return c.CardCode === activity.StudentCardCode });
        const regRegToCreate = {
            Status_request: "1", //new request
            ParentCardCode: household.CardCode,
            Parent1_Mail: household.Parent1_Mail,
            Parent1_ID: household.Parent1_ID,
            Parent1_FirstName: household.Parent1_FirstName,
            Parent1_LastName: household.Parent1_LastName,
            Parent2_Mail: household.Parent2_Mail,
            Parent2_ID: household.Parent2_ID,
            Parent2_FirstName: household.Parent2_FirstName,
            Parent2_LastName: household.Parent2_LastName,
            LicTradNum: child.LicTradNum,
            Pupil_FirstName: child.Pupil_FirstName,
            Pupil_lastName: child.Pupil_lastName,
            Class: child.Class,
            FinalPrice: activity.DiscPrice, //initialize final price field 
            ...activity
        }
        try {
            const createdReg = await addRegAPI(regRegToCreate)
            regRegToCreate._id = createdReg._id;
            regRegToCreate.selected = true
            return regRegToCreate;
        } catch (error) {
            console.log(error)
        }
    }
    const getActivReport = () => {
        if (!isParent) {
            setActivCode(props.ActivCode);
            navigate('/activitiesReport');
        }
    };
    const handleUnlock = (haveDeepLink) => {
        setActivityToUnlock(props);
        if (!haveDeepLink) {
            setUnlockActivityDrawer({ isOpen: true, level: 1 });
        }
        else {
            setUnlockActivityDrawer({ isOpen: true, level: 3 });
        }
    }

    //render login
    const startDate = dateFormat(props.StartDate);
    const endDate = dateFormat(props.EndDate);
    const activityDate = startDate !== endDate ? `${startDate}-${endDate}` : startDate;
    const endReg = dateFormat(props.EndReg);
    const disabeldEndDate = props.EndReg;
    const shortDesc = props.LongActDesc && props.LongActDesc.slice(0, 15);
    const expandIcon = expanded ? <Typography variant="greenSmallLink">{t("readLess")}</Typography> : <Typography variant="greenSmallLink">{t("readMore")}</Typography>;
    const switchDisabled = props.isOverlapped || props.disabled || datePast(disabeldEndDate);
    const selected = !!props._id;
    const checkBoxVisble =
        <Switch
            checked={selected}
            onChange={e => handleCart(e)}
            handleDiameter={29}
            offColor={switchDisabled ? "#ecfceb" : "#3a7735"}
            onColor="#ffbb41"
            offHandleColor="#fff"
            onHandleColor="#fff"
            disabled={switchDisabled}
            height={25}
            width={100}
            borderRadius={50}
            activeBoxShadow='none'
            className={`${classes.switch} ${selected ? classes.selectBorder : classes.unSelectBorder} ${switchDisabled && classes.noBorder} ${switchDisabled && classes.grayBG}`}
            uncheckedIcon={
                <Box>
                    {props.isOverlapped ?
                        <Typography sx={{ fontSize: "0.8rem", textAlign: 'center' }} >{t("overlappedActivity")}</Typography> :
                        datePast(disabeldEndDate) ?
                            <Typography sx={{ fontSize: "0.75rem", textAlign: 'center' }} >{t("registrationClosedShort")}</Typography> :
                            props.disabled ?
                                <Typography sx={{ fontSize: "0.8rem", textAlign: 'center' }}>{t("blocked")}</Typography>
                                :
                                <Typography sx={{ fontSize: "0.8rem", color: "#ffffff", paddingRight: "13px", fontWeight: "bold" }}>{t("addToCartShort")}</Typography>}
                </Box>
            }
            uncheckedHandleIcon={
                <Box alignItems='center' display='flex' justifyContent='center'>
                    {switchDisabled ?
                        <SubtitlesOffOutlinedIcon sx={{ color: "#fff", width: "0.8em" }} /> :
                        busy ?
                            <CustomBusy small={true} /> : <AddShoppingCartOutlinedIcon sx={{ color: "#3a7735", width: "0.8em" }} />}
                </Box>
            }
            checkedIcon={
                <Typography sx={{ fontSize: "0.8rem", color: "#3a7735", paddingRight: "4px", fontWeight: "bold" }}>{t("activityInCart")}</Typography>
            }
            checkedHandleIcon={
                <Box alignItems='center' display='flex' justifyContent='center' >
                    {busy ? <CustomBusy small={true} /> : <DeleteIcon sx={{ color: "#3a7735", width: "0.8em" }} />}
                </Box>
            }
        />

    const copyDeepLink = () => {
        navigator.clipboard.writeText(`${window.location.origin}/dl/${props.Code}-${props.ActivCode}-${props.Class}-0`);
        const key = enqueueSnackbar(t('copyActiv'), { variant: "success" });
        setTimeout(() => { closeSnackbar(key) }, 1500)
    }

    return (
        <Card sx={{ position: 'relative' }} className={`${classes.activityCard} ${!expanded && classes.activityCardHeight} ${(isParent && props.isOverlapped || props.disabled || datePast(disabeldEndDate)) && classes.disabledItem} ${selected && classes.itemSelected}`} >
            <ConditionalWrapper
                condition={!isParent}
                wrapper={children => <Link color='inherit' component='button' onClick={getActivReport}>{children}</Link>}>
                <CardHeader sx={{ padding: '0.25rem 1rem' }} title={props.ActivName} />
            </ConditionalWrapper>
            {props.disabled && !isParent && !datePast(props.StartDate) && !datePast(props.EndPort) &&
                <Tooltip title={!props.haveDeepLink ? t("pressToMakeLink") : t("pressToUpdateLink")}>
                    <IconButton onClick={e => handleUnlock(props.haveDeepLink)} sx={{ alignSelf: "flex-end", marginRight: "1rem", pointerEvents: 'all', background: "transparent !important" }} disableRipple={true}>
                        {!props.haveDeepLink ?
                            <LockOutlinedIcon sx={{ color: "#3a7735 !important", alignSelf: "flex-end", fontSize: '2rem' }} />
                            : <MoreTimeIcon sx={{ color: "#3a7735 !important", alignSelf: "flex-end", fontSize: '2rem' }} />
                        }
                    </IconButton>
                </Tooltip>}
            <CardContent sx={{ flex: 1, padding: '0.25rem 1rem' }}>
                <Box display='flex' alignItems="flex-start" minHeight="17%" flexDirection={'column'}>
                    <Chip label={`${t("classOf")} ${permanentInfo.childClasses[props.Class]}'`} size="small" className={classes.ActivChip} />
                    {props.LongActDesc && isParent && <MuiTypography >
                        {shortDesc}
                    </MuiTypography>}
                </Box>
                <Box display='flex' alignItems={'center'} minHeight="17%">
                    <EventIcon className={classes.iconStyle} fontSize='1rem' />
                    <MuiTypography >
                        {props.ItemPurposeType === "2" ? t("annual") : activityDate}
                    </MuiTypography>
                </Box>
                {!!props.Location && <Box display='flex' width={'100%'} alignItems={'center'} minHeight="17%">
                    <LocationOnIcon className={classes.iconStyle} fontSize='1rem' />
                    <MuiTypography  >
                        {props.Location}
                    </MuiTypography>
                </Box>}
                {endReg && <Box display='flex' width={'100%'} alignItems={'center'} minHeight="17%">
                    {props.disabled && !isParent ?
                        <MuiTypography marginLeft='0.2rem' variant="activityAlert" color="alert">
                            {t("registrationClosed")} ב{endReg}
                        </MuiTypography> :
                        <MuiTypography marginLeft='0.2rem' variant="activityAlert" color="alert">
                            {t("registrationEnd")} {endReg}
                        </MuiTypography>}
                </Box>}
                {!isParent && <Box display='flex' justifyContent={'space-between'} minHeight="17%">
                    <Box display='flex' alignItems={'center'}>
                        <LinkIcon className={classes.iconStyle} fontSize='1rem' />
                        <Link component="button" sx={{ fontWeight: "bold" }} onClick={copyDeepLink}>{t('copyActiveLink')}</Link>
                    </Box>
                </Box>}
            </CardContent>
            <CardActions sx={{ margin: '8px', padding: '0px' }}>
                <Box display='flex' flexDirection={'column'} width={'100%'} justifyContent={'space-between'} alignItems={'flex-start'}>
                    <Box display='flex' flexDirection={{ xs: "column", md: "row" }} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                        <Chip label={`${props.DiscPrice} ${"\u20AA"}`} sx={{ alignSelf: "flex-start !important" }} size="small" className={(props.disabled || datePast(disabeldEndDate)) ? classes.grayInfoLabel : classes.greenInfoLabel} />
                        {!!isParent ? checkBoxVisble : null}
                    </Box>
                    <Link component="button" sx={{ visibility: !props.LongActDesc && "hidden", padding: "0.25rem 0.5rem", alignSelf: "flex-start !important" }} onClick={e => setExpanded(!expanded)}>{expandIcon}</Link>
                </Box>
            </CardActions>
            <Collapse in={expanded} >
                <CardContent>
                    <MuiTypography fontSize="1rem">{props.LongActDesc}</MuiTypography>
                </CardContent>
            </Collapse>
        </Card>
    );
}

export const MuiTypography = (props) => {
    const { children } = props
    return (< Typography textAlign='start' variant="activitySmallText" sx={{ lineHeight: { xs: '12px', sm: '20px' } }} {...props} >
        {children}
    </Typography >)
}

export const dateFormat = (date) => {
    if (date) {
        const reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;
        return format(new Date(Number(reMsAjax.exec(date)[1])), 'dd/MM/yyyy');
    } else {
        return format(new Date('01-01-2001'), 'dd/MM/yyyy');
    }
}

export const datePast = (date) => {
    try {
        const reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;
        const today = new Date();
        const tempDate = new Date(Number(reMsAjax.exec(date)[1]));
        today.setHours(0, 0, 0, 0);

        return today > tempDate;
    } catch (error) {
        return false;
    }

}